import { FC } from 'react';
import { ReferralTransactions } from '../../features/show-referral-transactions';

export const ReferralTransactionsPage: FC = () => {
  return (
    <>
      <ReferralTransactions />
    </>
  );
};
