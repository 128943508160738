export enum ETokenSymbols {
    USDT = "USDT",
    OMD = "OMD",
    TIGR = "omdwTigr",
    CR = "omdwCRB",
    CONS = "omdwCons",
    CONT = "omdwCont",
    CHAI = "omdwChai",
    LED = "omdwLed",
    DELC = "omdwDelC",
    MAGIC = "omdwMS",
    ARA = "omAra",
    ARAORIG = "AG",
    Oo = "omOo",
    Crc = "omCrc",
    FP = "omFP",
    DB = "omDB",
    CRD = "omCRD",
    IIIA = "omIIIA",
    TolW = "omTolW",
    WeBi = "omWeBi",
    Zely = "omZely",
    Krk = "omKrk",
    SnS = "omSnS",
    QNod = "omQNod",
    GGq = "omGGq",
    Circl = "omCircl",
    TSpot = "omTSpot",
    EpGam = "omEpGam",
    FBlocks = "omFBlocks",
    
    
}
